import React from 'react';
import Navigation from '../components/navigation';
import Troll from '../components/troll.js';

class NotFound extends React.Component {
  render() {
    return (
      <>
        <Navigation />
        <main>
          <Troll top={'10em'}>
            <p>
              <em>Oi!</em>
              <br /> Wot ar' yooz' look'n at?!
              <br /> Nuffin' to see 'ere!
            </p>
          </Troll>
        </main>
      </>
    );
  }
}

export default NotFound;
