// GENERAL CONSTANTS
export const SITE_NAME = "Matt's  Citadel";
export const DEVELOPMENT = 'development';

// EXTERNAL CONSTANTS
export const NAME_GENERATOR_BASE_URL =
  process.env.NODE_ENV === DEVELOPMENT
    ? 'http://localhost:3000/'
    : 'https://warhammer-name-generator.onrender.com/';
export const NAME_GENERATOR_ENDPOINTS = {
  options: 'options',
  generate: 'generate',
};

// KEYBOARD
export const KEYS = {
  escape: 'Escape',
  backspace: 'Backspace',
  c: 'c',
  v: 'v',
  plus: '+',
  underscore: '_',
};

// BATTLE MAPPER
export const TERRAIN_TYPES = {
  ROCK: 'rock',
  ROCKS: 'rocks',
  HILL: 'hill',
  DOUBLE_HILL: 'double hill',
  TREE: 'tree',
  TREES: 'trees',
  HOUSE_A: 'house A',
  CORNER_WALL: 'corner wall',
  CORNER_HILL_A: 'corner hill A',
  POND_A: 'pond A',
  ROAD_A: 'road A',
  TOWER: 'tower',
};
