// Libraries
import React from 'react';
import { Link } from 'gatsby';
// Styles
import * as styles from './navigation.module.css';
// Utils
import * as Constants from '../utils/constants';
import { HOME } from '../utils/routeConstants';
import routes from '../utils/routes';

const Bullet = () => <li className={styles.navigationBullet}>●</li>;

const renderNavigation = () => {
  return routes.map(({ route, name }, index) => (
    <React.Fragment key={`${name}-${index + 1}`}>
      <li className={styles.navigationItem}>
        <Link to={route} activeClassName="active">
          {name}
        </Link>
      </li>
      {index < routes.length - 1 && <Bullet />}
    </React.Fragment>
  ));
};

const Navigation = () => (
  <nav role="navigation" className={styles.container} aria-label="Main">
    <Link to={HOME} className={styles.logoLink}>
      <span className={styles.logo} />
      <span className={styles.navigationItem}>{Constants.SITE_NAME}</span>
    </Link>
    <ul className={styles.navigation}>{renderNavigation()}</ul>
  </nav>
);

export default Navigation;
