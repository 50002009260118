import {
  HOME,
  BLOG,
  LORE,
  RULES,
  ABOUT,
  GALLERY,
  LINKS,
  TOOLS,
} from './routeConstants';

const routes = [
  {
    name: 'Home',
    route: HOME,
  },
  {
    name: 'Blog',
    route: BLOG,
  },
  {
    name: 'Gallery',
    route: GALLERY,
  },
  // {
  //   name: 'Lore',
  //   route: LORE,
  // },
  {
    name: 'Rules',
    route: RULES,
  },
  // {
  //   name: 'Links',
  //   route: LINKS,
  // },
  // {
  //   name: 'About',
  //   route: ABOUT,
  // },
  {
    name: 'Tools',
    route: TOOLS,
  },
];

export default routes;
