export const HOME = '/';
export const BLOG = '/blog/';
export const GALLERY = '/gallery/';
export const LORE = '/lore/';
export const RULES = '/rules/';
export const ABOUT = '/about/';
export const LINKS = '/links/';
export const LEGAL = '/legal/';
export const TOOLS = '/tools/';
export const BLOG_SHOW = (slug) => BLOG + slug;
export const GALLERY_SHOW = (slug) => GALLERY + slug;
export const RULES_SHOW = (slug) => RULES + slug;
